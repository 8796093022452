var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "mew-component--module-side-apr bgWalletBlockDark pa-5 border-radius--10px",
    },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "d-flex align-center justify-space-between pt-2" },
        [
          _c(
            "div",
            {
              staticClass:
                "textLight--text text-uppercase mew-label font-weight-medium set-font",
            },
            [_vm._v(" Total stake: ")]
          ),
          !_vm.loading
            ? _c("div", { staticClass: "set-font" }, [
                _vm._v(_vm._s(_vm.stake) + " MEWcbETH"),
              ])
            : _c("v-skeleton-loader", {
                attrs: { width: "100px", "max-height": "48px", type: "text" },
              }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex align-center justify-space-between pt-2" },
        [
          _c(
            "div",
            {
              staticClass:
                "textLight--text text-uppercase mew-label font-weight-medium set-font",
            },
            [_vm._v(" ETH value: ")]
          ),
          !_vm.loading
            ? _c("div", { staticClass: "set-font" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.stakeInETH) +
                    " " +
                    _vm._s(_vm.currencyName) +
                    " "
                ),
              ])
            : _c("v-skeleton-loader", {
                attrs: { width: "100px", "max-height": "48px", type: "text" },
              }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex align-center justify-space-between pt-2" },
        [
          _c(
            "div",
            {
              staticClass:
                "textLight--text text-uppercase mew-label font-weight-medium set-font",
            },
            [_vm._v(" Exitable " + _vm._s(_vm.currencyName) + ": ")]
          ),
          !_vm.loading
            ? _c("div", { staticClass: "set-font" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.exitableETH) +
                    " " +
                    _vm._s(_vm.currencyName) +
                    " "
                ),
              ])
            : _c("v-skeleton-loader", {
                attrs: { width: "100px", "max-height": "48px", type: "text" },
              }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex align-center justify-space-between pt-2" },
        [
          _c(
            "div",
            {
              staticClass:
                "textLight--text text-uppercase mew-label font-weight-medium set-font",
            },
            [_vm._v(" Claimable Stake: ")]
          ),
          !_vm.loading
            ? _c("div", { staticClass: "set-font" }, [
                _vm._v(_vm._s(_vm.claimableStake) + " MEWcbETH"),
              ])
            : _c("v-skeleton-loader", {
                attrs: { width: "100px", "max-height": "48px", type: "text" },
              }),
        ],
        1
      ),
      _vm.showClaimNow
        ? _c(
            "div",
            {
              staticClass:
                "d-flex flex-column align-center justify-space-between pt-3 pb-2",
            },
            [
              _c("mew-button", {
                attrs: {
                  title: "Claim now",
                  "has-full-width": "",
                  "btn-size": "medium",
                  loading: _vm.loadingClaim,
                  disabled: !_vm.hasEnoughBalanceToStake,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.claim.apply(null, arguments)
                  },
                },
              }),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.hasEnoughBalanceToStake,
                      expression: "!hasEnoughBalanceToStake",
                    },
                  ],
                  staticClass: "error--text",
                },
                [
                  _vm._v(" Not enough balance to claim. "),
                  _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.network.type.canBuy,
                          expression: "network.type.canBuy",
                        },
                      ],
                      on: {
                        click: function () {
                          _vm.openBuySell("CoinbaseStakingSummary")
                        },
                      },
                    },
                    [
                      _vm._v(
                        "Buy More " + _vm._s(_vm.network.type.currencyName)
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "d-flex align-center justify-space-between pt-2" },
        [
          _c(
            "div",
            {
              staticClass:
                "textLight--text text-uppercase mew-label font-weight-medium set-font",
            },
            [_vm._v(" Pending Exit: ")]
          ),
          !_vm.loading
            ? _c("div", { staticClass: "set-font" }, [
                _vm._v(" " + _vm._s(_vm.stakePendingExit) + " MEWcbETH "),
              ])
            : _c("v-skeleton-loader", {
                attrs: { width: "100px", "max-height": "48px", type: "text" },
              }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex align-center justify-space-between pt-2" },
        [
          _c(
            "div",
            {
              staticClass:
                "textLight--text text-uppercase mew-label font-weight-medium set-font",
            },
            [_vm._v(" Refreshes in: ")]
          ),
          !_vm.loading
            ? _c("div", { staticClass: "set-font" }, [
                _vm._v(_vm._s(_vm.ticker)),
              ])
            : _c("div", [_vm._v("-- Hours and -- mins")]),
        ]
      ),
      _vm._m(1),
      _c(
        "div",
        { staticClass: "d-flex align-center justify-space-between pt-2" },
        [
          _c("mew-button", {
            attrs: {
              title: "Refresh Manually",
              "has-full-width": "",
              "btn-style": "outline",
              "btn-size": "medium",
              loading: _vm.loading,
            },
            nativeOn: {
              click: function ($event) {
                return _vm.fetchInfo.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "d-flex align-center justify-space-between mb-1" },
      [
        _c(
          "div",
          { staticClass: "text-uppercase mew-header-4 font-weight-medium" },
          [_vm._v(" ETH Staking Summary ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "d-flex align-center justify-space-between" },
      [
        _c(
          "div",
          {
            staticClass:
              "greenPrimary--text mew-label font-weight-medium set-font",
          },
          [_vm._v(" (Refreshes daily at 1PM UTC) ")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }