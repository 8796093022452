var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "div",
        { staticClass: "px-8 py-4 gdpr-banner" },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { staticClass: "text-style", attrs: { cols: "12", lg: "9" } },
                [
                  _vm._v(
                    " We use cookies and similar technologies to enhance your experience, analyze site usage, and support our operations. By continuing to use our site, you agree to our use of cookies and our data processing practices as detailed in our Privacy and Cookie Policy. You can choose to decline or withdraw your consent at any time. For more information see our "
                  ),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://www.myetherwallet.com/privacy-policy",
                        target: "_blank",
                      },
                    },
                    [_vm._v("Privacy Policy")]
                  ),
                  _vm._v(". "),
                ]
              ),
              _c(
                "v-col",
                {
                  staticClass:
                    "d-flex align-center justify-center justify-md-space-around pt-10 pt-lg-0",
                  attrs: { cols: "12", lg: "3" },
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "reject-button mr-3",
                      attrs: { large: "", outlined: "" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.handleReject.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v(" Reject ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "accept-button",
                      attrs: { large: "" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.handleAccept.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v(" Accept")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a",
            {
              staticClass: "close-icon",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return function () {
                    return _vm.handleReject()
                  }.apply(null, arguments)
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-close")])],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }