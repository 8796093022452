var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "field-card" }, [
        _c("div", { staticClass: "field-label" }, [
          _vm._v(_vm._s(_vm.$t("flyover.pegin.deposit.amount"))),
        ]),
        _c(
          "div",
          { staticClass: "field-value" },
          [
            _c("p", { staticClass: "field-text" }, [
              _vm._v(_vm._s(String(_vm.finalAmount))),
            ]),
            _c(
              "app-copy-btn",
              { attrs: { "copy-value": String(_vm.finalAmount) } },
              [
                _c(
                  "v-btn",
                  { attrs: { "x-small": "", icon: "", color: "greenPrimary" } },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/icons/icon-copy-green.svg"),
                        alt: "copy",
                        height: "13",
                      },
                    }),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "field-card" }, [
        _c("div", { staticClass: "field-label" }, [
          _vm._v(_vm._s(_vm.$t("flyover.pegin.deposit.address"))),
        ]),
        _c(
          "div",
          { staticClass: "field-value" },
          [
            _c("p", { staticClass: "field-text" }, [
              _vm._v(_vm._s(String(_vm.bitcoinDepositAddressHash))),
            ]),
            _c(
              "app-copy-btn",
              {
                attrs: { "copy-value": String(_vm.bitcoinDepositAddressHash) },
              },
              [
                _c(
                  "v-btn",
                  { attrs: { "x-small": "", icon: "", color: "greenPrimary" } },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/icons/icon-copy-green.svg"),
                        alt: "copy",
                        height: "13",
                      },
                    }),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "mew-alert",
        {
          staticClass:
            "font-weight-light d-flex justify-space-between align-center mb-7 mt-7",
          attrs: { "hide-close-icon": "" },
        },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("flyover.pegin.deposit.nextStep", {
                  amount: _vm.finalAmount,
                  address: _vm.bitcoinDepositAddressHash,
                  blocks: _vm.confirmations,
                })
              ) +
              " "
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "timer-container" },
        [
          _vm.tickerActive
            ? _c("v-progress-linear", {
                attrs: { indeterminate: "", color: "greenPrimary" },
              })
            : _vm._e(),
          _c("div", { staticClass: "ticket-subtitle greenPrimary--text" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("flyover.pegin.deposit.deadline", {
                    ticker: _vm.ticker,
                  })
                ) +
                " "
            ),
          ]),
          !_vm.tickerActive
            ? _c("div", { staticClass: "ticket-subtitle redPrimary--text" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("flyover.pegin.deposit.expired")) + " "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c("mew-button", {
        attrs: {
          disabled: !_vm.tickerActive || _vm.isDone,
          "has-full-width": true,
          "btn-size": "xlarge",
          title: _vm.$t("flyover.pegin.deposit.done"),
        },
        nativeOn: {
          click: function ($event) {
            return _vm.done.apply(null, arguments)
          },
        },
      }),
      _vm.isDone
        ? _c(
            "div",
            [
              _c(
                "mew-alert",
                {
                  staticClass:
                    "font-weight-light d-flex justify-space-between align-center mb-7 mt-7",
                  attrs: { "hide-close-icon": "" },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("flyover.pegin.deposit.doneStep", {
                          blocks: _vm.confirmations,
                        })
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }