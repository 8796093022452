var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "field-card" }, [
        _c("div", { staticClass: "field-label" }, [
          _vm._v(_vm._s(_vm.$t("flyover.pegout.accept.amount"))),
        ]),
        _c(
          "div",
          { staticClass: "field-value" },
          [
            _c("p", { staticClass: "field-text" }, [
              _vm._v(_vm._s(String(_vm.quoteAmount))),
            ]),
            _c(
              "app-copy-btn",
              { attrs: { "copy-value": String(_vm.quoteAmount) } },
              [
                _c(
                  "v-btn",
                  { attrs: { "x-small": "", icon: "", color: "greenPrimary" } },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/icons/icon-copy-green.svg"),
                        alt: "copy",
                        height: "13",
                      },
                    }),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "field-card" }, [
        _c("div", { staticClass: "field-label" }, [
          _vm._v(
            " " + _vm._s(_vm.$t("flyover.pegout.accept.confirmations")) + " "
          ),
        ]),
        _c(
          "div",
          { staticClass: "field-value" },
          [
            _c("p", { staticClass: "field-text" }, [
              _vm._v(_vm._s(String(_vm.confirmations))),
            ]),
            _c(
              "app-copy-btn",
              { attrs: { "copy-value": String(_vm.confirmations) } },
              [
                _c(
                  "v-btn",
                  { attrs: { "x-small": "", icon: "", color: "greenPrimary" } },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/icons/icon-copy-green.svg"),
                        alt: "copy",
                        height: "13",
                      },
                    }),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "field-card" }, [
        _c("div", { staticClass: "field-label" }, [
          _vm._v(_vm._s(_vm.$t("flyover.pegout.accept.hash"))),
        ]),
        _c(
          "div",
          { staticClass: "field-value" },
          [
            _c("p", { staticClass: "field-text" }, [
              _vm._v(_vm._s(String(_vm.quoteHash))),
            ]),
            _c(
              "app-copy-btn",
              { attrs: { "copy-value": _vm.quoteHash } },
              [
                _c(
                  "v-btn",
                  { attrs: { "x-small": "", icon: "", color: "greenPrimary" } },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/icons/icon-copy-green.svg"),
                        alt: "copy",
                        height: "13",
                      },
                    }),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", {
        staticClass: "g-recaptcha",
        attrs: { id: "g-recaptcha", "data-sitekey": _vm.siteKey },
      }),
      _c(
        "v-row",
        { staticClass: "mx-0" },
        [
          _c("v-col", { staticClass: "pr-0", attrs: { cols: "8" } }, [
            _c("div", { staticClass: "api-error" }, [_vm._v(_vm._s(_vm.msg))]),
          ]),
          _c(
            "v-col",
            { staticClass: "pl-0", attrs: { cols: "4" } },
            [
              _c("mew-button", {
                attrs: {
                  loading: _vm.loading,
                  disabled: _vm.loading,
                  "has-full-width": true,
                  "btn-size": "xlarge",
                  title: _vm.$t("flyover.pegout.accept.acceptBtn"),
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.submit.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }