var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "py-8 px-8 moonpay-buy-component" },
    [
      _c("div", { staticClass: "mt-2" }, [
        _c("div", { staticClass: "font-weight-medium textDark--text mb-2" }, [
          _vm._v(" How much do you want to spend? "),
        ]),
        _c(
          "div",
          { staticClass: "d-flex align-center" },
          [
            _c("mew-input", {
              staticClass: "no-right-border",
              staticStyle: { "max-height": "92px", "max-width": "251px" },
              attrs: {
                "hide-clear-btn": "",
                type: "number",
                "error-messages": _vm.amountErrorMessages,
              },
              nativeOn: {
                keydown: function ($event) {
                  return _vm.preventCharE($event)
                },
              },
              model: {
                value: _vm.amount,
                callback: function ($$v) {
                  _vm.amount = $$v
                },
                expression: "amount",
              },
            }),
            _c("mew-select", {
              staticClass: "selectedFiat no-left-border",
              staticStyle: { "max-width": "135px", "margin-top": "-30px" },
              attrs: { items: _vm.fiatCurrencyItems, "is-custom": "" },
              model: {
                value: _vm.selectedFiat,
                callback: function ($$v) {
                  _vm.selectedFiat = $$v
                },
                expression: "selectedFiat",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "mt-2" }, [
        _c(
          "div",
          { staticClass: "d-flex align-center mb-2" },
          [
            _c(
              "div",
              { staticClass: "font-weight-medium textDark--text mr-1" },
              [_vm._v("You will get")]
            ),
            _c("mew-tooltip", {
              staticStyle: { height: "21px" },
              scopedSlots: _vm._u([
                {
                  key: "contentSlot",
                  fn: function () {
                    return [
                      _c("div", [
                        _vm._v(" " + _vm._s(_vm.includesFeeText) + " "),
                        _c("br"),
                        _c("br"),
                        _vm._v(" " + _vm._s(_vm.networkFeeText) + " "),
                        _c("br"),
                        _c("br"),
                        _vm._v(" " + _vm._s(_vm.dailyLimit) + " "),
                        _c("br"),
                        _vm._v(" " + _vm._s(_vm.monthlyLimit) + " "),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex align-start" },
          [
            _c("mew-input", {
              staticClass: "no-right-border",
              attrs: {
                "is-read-only": "",
                value: !_vm.loading ? "" + _vm.cryptoToFiat : "Loading...",
                "hide-clear-btn": "",
              },
            }),
            _c(
              "div",
              {
                staticClass: "d-flex align-center token-select-button",
                on: {
                  click: function ($event) {
                    _vm.openTokenSelect = true
                  },
                },
              },
              [
                _c("mew-token-container", {
                  attrs: { img: _vm.selectedCurrency.img, size: "28px" },
                }),
                _c("div", { staticClass: "basic--text ml-2" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("concatName")(_vm.selectedCurrency.symbol)
                      ) +
                      " "
                  ),
                ]),
                _c(
                  "v-icon",
                  {
                    staticClass: "ml-auto",
                    attrs: { size: "20px", color: "titlePrimary" },
                  },
                  [_vm._v(" mdi-chevron-down ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      !_vm.inWallet
        ? _c(
            "div",
            { staticClass: "mt-2" },
            [
              _c(
                "div",
                { staticClass: "font-weight-medium textDark--text mb-2" },
                [_vm._v(" Where should we send your crypto? ")]
              ),
              _c("module-address-book", {
                ref: "addressInput",
                attrs: {
                  label: "Enter Crypto Address",
                  currency: _vm.selectedCryptoName,
                  "enable-save-address": false,
                  "is-home-page": true,
                },
                on: { setAddress: _vm.setAddress },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("mew-button", {
        staticClass: "mt-2",
        attrs: {
          "btn-size": "xlarge",
          "has-full-width": "",
          disabled: _vm.disableBuy,
          title: _vm.buyBtnTitle,
          "is-valid-address-func": _vm.isValidToAddress,
        },
        nativeOn: {
          click: function ($event) {
            return _vm.buy.apply(null, arguments)
          },
        },
      }),
      _c("buy-sell-token-select", {
        attrs: {
          open: _vm.openTokenSelect,
          "currency-items": _vm.tokens,
          "selected-currency": _vm.selectedCurrency,
          "set-currency": _vm.setCurrency,
          "in-wallet": _vm.inWallet,
        },
        on: {
          close: function ($event) {
            _vm.openTokenSelect = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }