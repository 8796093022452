var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("mew-select", {
        staticClass: "mr-3 flex-grow-1 mb-4",
        attrs: {
          "has-filter": false,
          label: _vm.$t("flyover.pegin.provider"),
          items: _vm.providers,
          "normal-dropdown": "",
        },
        on: { input: _vm.setProvider },
      }),
      _c("mew-input", {
        staticClass: "mr-3 flex-grow-1",
        attrs: {
          "has-clear-btn": false,
          label: _vm.$t("flyover.pegin.quote.rskAddress"),
          placeholder: _vm.$t("flyover.pegin.quote.rskAddress"),
        },
        model: {
          value: _vm.rskAddress,
          callback: function ($$v) {
            _vm.rskAddress = $$v
          },
          expression: "rskAddress",
        },
      }),
      _c("mew-input", {
        staticClass: "mr-3 flex-grow-1",
        attrs: {
          "has-clear-btn": false,
          label: _vm.$t("flyover.pegin.quote.btcAddress"),
          placeholder: _vm.$t("flyover.pegin.quote.btcAddress"),
        },
        model: {
          value: _vm.btcAddress,
          callback: function ($$v) {
            _vm.btcAddress = $$v
          },
          expression: "btcAddress",
        },
      }),
      _c(
        "div",
        { staticClass: "btc-amount" },
        [
          _c("mew-input", {
            staticClass: "mr-3 flex-grow-1",
            attrs: {
              "has-clear-btn": false,
              label: _vm.$t("flyover.pegin.quote.amountBTC"),
              placeholder: _vm.$t("flyover.pegin.quote.amountBTC"),
            },
            model: {
              value: _vm.amount,
              callback: function ($$v) {
                _vm.amount = $$v
              },
              expression: "amount",
            },
          }),
          _c("div", { staticClass: "notes" }, [_vm._v(_vm._s(_vm.amountNote))]),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mx-0" },
        [
          _c("v-col", { staticClass: "pr-0", attrs: { cols: "8" } }, [
            _c("div", { staticClass: "api-error" }, [_vm._v(_vm._s(_vm.msg))]),
          ]),
          _c(
            "v-col",
            { staticClass: "pl-0", attrs: { cols: "4" } },
            [
              _c("div", {
                staticClass: "g-recaptcha",
                attrs: {
                  "data-sitekey": "6Lc5ZdAoAAAAAPralWgHZ5ma8ri_xE6g2YN_Obpc",
                },
              }),
              _c("mew-button", {
                attrs: {
                  loading: _vm.loading,
                  disabled: _vm.loading,
                  "has-full-width": true,
                  "btn-size": "xlarge",
                  title: _vm.$t("flyover.pegin.quote.quoteBtn"),
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.getQuote.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }