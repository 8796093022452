import { render, staticRenderFns } from "./AccessWalletKeepkey.vue?vue&type=template&id=31d2a2cd&scoped=true"
import script from "./AccessWalletKeepkey.vue?vue&type=script&lang=js"
export * from "./AccessWalletKeepkey.vue?vue&type=script&lang=js"
import style0 from "./AccessWalletKeepkey.vue?vue&type=style&index=0&id=31d2a2cd&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31d2a2cd",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/XDCWallet/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('31d2a2cd')) {
      api.createRecord('31d2a2cd', component.options)
    } else {
      api.reload('31d2a2cd', component.options)
    }
    module.hot.accept("./AccessWalletKeepkey.vue?vue&type=template&id=31d2a2cd&scoped=true", function () {
      api.rerender('31d2a2cd', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/access-wallet/hardware/components/AccessWalletKeepkey.vue"
export default component.exports