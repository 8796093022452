var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("app-modal", {
        attrs: {
          show: _vm.showModal,
          title: _vm.title,
          "btn-action": _vm.submit,
          close: _vm.reset,
          "btn-enabled": _vm.enableButton,
        },
        on: { close: _vm.reset },
        scopedSlots: _vm._u([
          {
            key: "dialogBody",
            fn: function () {
              return [
                _vm.openHardwarePassword
                  ? _c("hardware-password-modal", {
                      on: { password: _vm.setPassword, setTerms: _vm.setTerms },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("error-msg", {
        attrs: {
          show: _vm.openError,
          close: _vm.reset,
          resolve: _vm.send,
          error: _vm.errors,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }