var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wallet-main" },
    [
      _c("the-wallet-side-menu"),
      _c(
        "v-main",
        [
          _c(
            "v-container",
            { staticClass: "pa-2 pa-md-3 mb-14", attrs: { fluid: "" } },
            [
              _c("the-wallet-header"),
              _vm.address ? _c("module-confirmation") : _vm._e(),
              _c("router-view"),
            ],
            1
          ),
        ],
        1
      ),
      _c("the-wallet-footer", {
        attrs: { "is-offline-app": _vm.isOfflineApp },
      }),
      _c("module-paper-wallet", {
        attrs: {
          open: _vm.showPaperWallet,
          close: _vm.closePaperWallet,
          "is-offline-app": _vm.isOfflineApp,
        },
        on: { close: _vm.closePaperWallet },
      }),
      _c(
        "v-dialog",
        {
          staticClass: "pk-survey",
          attrs: {
            value: _vm.showSurvey,
            "max-width": "320",
            "content-class": _vm.$vuetify.breakpoint.smAndUp
              ? "position-right survey-dialog ma-0"
              : "survey-dialog ma-0",
            persistent: "",
            "no-click-animation": "",
            "hide-overlay": "",
            "retain-focus": false,
          },
        },
        [
          _c(
            "div",
            { staticClass: "inner-container pa-5" },
            [
              _c(
                "div",
                { staticClass: "d-flex justify-end" },
                [
                  _c(
                    "v-icon",
                    {
                      attrs: { size: "x-large", color: "grey cursor--pointer" },
                      on: { click: _vm.closeSurveyModal },
                    },
                    [_vm._v(" mdi-close ")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "text-center font-weight-bold font-size--16px pt-2",
                },
                [_vm._v(" Want to make XOW even better? ")]
              ),
              _c("div", { staticClass: "px-4 py-2" }, [
                _vm._v(
                  " Please take a quick survey about your experience. We appreciate you! "
                ),
              ]),
              _c("mew-button", {
                attrs: {
                  title: "Click Here",
                  "has-full-width": true,
                  "btn-link": "https://mewwallet.typeform.com/pkuser-research",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.openSurvey.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }