var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.isMobile
      ? _c(
          "div",
          [
            _c(
              "mew-light-table",
              {
                attrs: {
                  "border-around": "",
                  "round-corner": "",
                  background: "",
                  "full-width": "",
                  flat: "",
                },
              },
              [
                _c("table", [
                  _c("thead", [
                    _c("tr", [
                      _c("td", [_vm._v("#")]),
                      _c("td", [_vm._v("ADDRESS")]),
                      _c("td", [_vm._v("NICKNAME")]),
                      _c("td"),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    _vm._l(_vm.tableData, function (td, dataKey) {
                      return _c("tr", { key: dataKey }, [
                        _c("td", { staticClass: "mew-label" }, [
                          _vm._v(_vm._s(td.number)),
                        ]),
                        _c("td", [
                          _c(
                            "div",
                            {
                              staticClass: "d-flex align-center flex-shrink-1",
                              staticStyle: {
                                width: "25vw",
                                "max-width": "225px",
                              },
                            },
                            [
                              _c("mew-blockie", {
                                staticClass: "mr-1",
                                attrs: {
                                  height: "20px",
                                  width: "20px",
                                  address: td.address,
                                },
                              }),
                              _c("mew-tooltip", {
                                staticClass: "address-container",
                                attrs: { text: td.address, "hide-icon": "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "contentSlot",
                                      fn: function () {
                                        return [
                                          _c("mew-transform-hash", {
                                            staticClass: "mr-2 mew-label",
                                            attrs: { hash: td.address },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c(
                                "app-copy-btn",
                                { attrs: { "copy-value": td.address } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        "x-small": "",
                                        icon: "",
                                        color: "greenPrimary",
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/images/icons/icon-copy-green.svg"),
                                          alt: "copy",
                                          height: "13",
                                        },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm.explorerAddr(td) !== ""
                                ? _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: _vm.explorerAddr(td),
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            "x-small": "",
                                            icon: "",
                                            color: "greenPrimary",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("@/assets/images/icons/icon-arrow-top-right-green.svg"),
                                              alt: "copy",
                                              height: "13",
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "td",
                          {
                            staticClass: "mew-label",
                            staticStyle: {
                              "max-width": "95px",
                              overflow: "hidden",
                              "white-space": "nowrap",
                              "text-overflow": "ellipsis",
                            },
                          },
                          [_vm._v(" " + _vm._s(td.nickname) + " ")]
                        ),
                        _c(
                          "td",
                          [
                            _c(
                              "mew-button",
                              {
                                attrs: {
                                  "btn-style": "transparent",
                                  "btn-size": "small",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.onEdit(td)
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "mew-label" }, [
                                  _vm._v("Edit"),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ])
                    }),
                    0
                  ),
                ]),
              ]
            ),
          ],
          1
        )
      : _c(
          "div",
          _vm._l(_vm.tableData, function (td, dataKey) {
            return _c(
              "mew-light-table",
              {
                key: dataKey,
                staticClass: "mb-2",
                attrs: {
                  "full-width": "",
                  "border-around": "",
                  "padding-around": "",
                  flat: "",
                  "round-corner": "",
                  "mobile-background": "",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex align-center justify-space-between" },
                  [
                    _c("div", { staticClass: "mew-label font-weight-bold" }, [
                      _vm._v("Nickname"),
                    ]),
                    _c("div", { staticClass: "mew-label" }, [
                      _vm._v(_vm._s(td.nickname)),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "d-flex align-center justify-space-between" },
                  [
                    _c("div", { staticClass: "mew-label font-weight-bold" }, [
                      _vm._v("Address"),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "d-flex align-center flex-shrink-1",
                        staticStyle: { width: "38vw", "max-width": "160px" },
                      },
                      [
                        _c("mew-transform-hash", {
                          staticClass: "mr-1 mew-label",
                          attrs: { hash: td.address },
                        }),
                        _c(
                          "app-copy-btn",
                          { attrs: { "copy-value": td.address } },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  "x-small": "",
                                  icon: "",
                                  color: "greenPrimary",
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/icons/icon-copy-green.svg"),
                                    alt: "copy",
                                    height: "12",
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm.explorerAddr(td) !== ""
                          ? _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.explorerAddr(td),
                                  target: "_blank",
                                },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      "x-small": "",
                                      icon: "",
                                      color: "greenPrimary",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/images/icons/icon-arrow-top-right-green.svg"),
                                        alt: "copy",
                                        height: "12",
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "text-right mt-2" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          outlined: "",
                          color: "greenPrimary",
                          small: "",
                          depressed: "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onEdit(td)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "greenPrimary--text mew-label text-transform--none",
                          },
                          [_vm._v(" Edit ")]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            )
          }),
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }