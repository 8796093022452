var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pa-8 moonpay-sell-component" },
    [
      _c("div", { staticClass: "mt-2" }, [
        _c(
          "div",
          { staticClass: "mb-2 d-flex align-center justify-space-between" },
          [
            _c("div", { staticClass: "font-weight-medium textDark--text" }, [
              _vm._v(" How much do you want to sell? "),
            ]),
            !_vm.loading && !_vm.fetchingBalance
              ? _c("app-button-balance", {
                  staticStyle: { position: "relative", top: "0", right: "0" },
                  attrs: { balance: _vm.selectedBalance, loading: _vm.loading },
                })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex align-start" },
          [
            _vm.inWallet
              ? _c("mew-input", {
                  staticClass: "no-right-border",
                  staticStyle: { "max-width": "251px", "max-height": "86px" },
                  attrs: {
                    "hide-clear-btn": "",
                    type: "number",
                    placeholder: "Enter amount to sell",
                    "max-btn-obj": _vm.maxButton,
                    disabled: _vm.loading,
                    "error-messages": _vm.errorMessages,
                    "persistent-hint": _vm.hasPersistentHint,
                    hint: _vm.persistentHintMessage,
                  },
                  nativeOn: {
                    keydown: function ($event) {
                      return _vm.preventCharE($event)
                    },
                  },
                  model: {
                    value: _vm.amount,
                    callback: function ($$v) {
                      _vm.amount = $$v
                    },
                    expression: "amount",
                  },
                })
              : _c("mew-input", {
                  staticClass: "no-right-border",
                  staticStyle: { "max-width": "251px", "max-height": "86px" },
                  attrs: {
                    "hide-clear-btn": "",
                    type: "number",
                    placeholder: "Enter amount to sell",
                    "max-btn-obj": _vm.maxButton,
                    disabled: _vm.loading,
                    "error-messages": _vm.errorMessages,
                    "persistent-hint": _vm.hasPersistentHint,
                    hint: _vm.persistentHintMessage,
                  },
                  nativeOn: {
                    keydown: function ($event) {
                      return _vm.preventCharE($event)
                    },
                  },
                  model: {
                    value: _vm.amount,
                    callback: function ($$v) {
                      _vm.amount = $$v
                    },
                    expression: "amount",
                  },
                }),
            _c(
              "div",
              {
                staticClass: "d-flex align-center token-select-button",
                on: {
                  click: function ($event) {
                    _vm.openTokenSelect = true
                  },
                },
              },
              [
                _c("mew-token-container", {
                  attrs: { img: _vm.selectedCurrency.img, size: "28px" },
                }),
                _c(
                  "div",
                  {
                    staticClass: "basic--text",
                    staticStyle: { "margin-left": "8px" },
                  },
                  [_vm._v(" " + _vm._s(_vm.selectedCurrency.symbol) + " ")]
                ),
                _c(
                  "v-icon",
                  {
                    staticClass: "ml-auto",
                    attrs: { size: "20px", color: "titlePrimary" },
                  },
                  [_vm._v(" mdi-chevron-down ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "mt-2" }, [
        _c("div", { staticClass: "font-weight-medium textDark--text mb-2" }, [
          _vm._v("You will get"),
        ]),
        _c(
          "div",
          { staticClass: "d-flex align-start" },
          [
            _c("mew-input", {
              staticClass: "no-right-border",
              attrs: {
                "is-read-only": "",
                value: _vm.cryptoAmount,
                "hide-clear-btn": "",
                type: "number",
              },
            }),
            _c("mew-select", {
              staticClass: "selectedFiat no-left-border",
              staticStyle: { "max-width": "135px" },
              attrs: { items: _vm.fiatCurrencyItems, "is-custom": "" },
              model: {
                value: _vm.selectedFiat,
                callback: function ($$v) {
                  _vm.selectedFiat = $$v
                },
                expression: "selectedFiat",
              },
            }),
          ],
          1
        ),
      ]),
      !_vm.inWallet
        ? _c(
            "div",
            { staticClass: "mt-2" },
            [
              _c(
                "div",
                { staticClass: "font-weight-medium textDark--text mb-2" },
                [_vm._v(" What wallet are you sending your crypto from? ")]
              ),
              _c("module-address-book", {
                ref: "addressInput",
                attrs: {
                  label: "Enter Crypto Address",
                  "enable-save-address": false,
                  "is-home-page": true,
                },
                on: { setAddress: _vm.setAddress },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "pt-2 pb-10" }, [
        _c("div", { staticClass: "mew-body textMedium--text" }, [
          _vm._v(
            " After submitting your sell order, you will have to send your crypto to Moonpay. Make sure to have enough currency in your wallet to cover network transaction fees. "
          ),
        ]),
        _vm.inWallet
          ? _c(
              "div",
              { staticClass: "d-flex align-center justify-space-between mt-4" },
              [
                _c("div", { staticClass: "mew-body textMedium--text" }, [
                  _vm._v("Estimated Network Fee"),
                ]),
                !_vm.estimatingFees
                  ? _c("div", { staticClass: "mew-body textMedium--text" }, [
                      _vm._v(" ~" + _vm._s(_vm.txFeeInEth) + " "),
                    ])
                  : _c("v-skeleton-loader", {
                      attrs: { type: "text", width: "150px" },
                    }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("mew-button", {
        attrs: {
          title: "SELL WITH MOONPAY",
          "btn-size": "xlarge",
          "has-full-width": "",
          disabled: _vm.disableSell,
          "is-valid-address-func": _vm.isValidToAddress,
        },
        nativeOn: {
          click: function ($event) {
            return _vm.sell.apply(null, arguments)
          },
        },
      }),
      _c("buy-sell-token-select", {
        attrs: {
          open: _vm.openTokenSelect,
          "currency-items": _vm.preselectedCurrencies,
          "selected-currency": _vm.selectedCurrency,
          "set-currency": _vm.setCurrency,
          "in-wallet": _vm.inWallet,
          "is-sell": "",
        },
        on: {
          close: function ($event) {
            _vm.openTokenSelect = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }