var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mew-component--swap" },
    [
      _c(
        "white-sheet",
        [
          _c("mew-module", {
            staticClass: "d-flex flex-grow-1 pt-6",
            attrs: {
              "has-elevation": true,
              "has-indicator": true,
              title: "Swap",
            },
            scopedSlots: _vm._u(
              [
                _vm.isAvailable
                  ? {
                      key: "moduleBody",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "input-swap-container pt-7 pb-3 px-5",
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass:
                                    "align-start justify-space-between mt-4",
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-0 pb-sm-3 pr-sm-0",
                                      attrs: { cols: "12", sm: "5" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "position--relative" },
                                        [
                                          _c("app-button-balance", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !_vm.isFromNonChain,
                                                expression: "!isFromNonChain",
                                              },
                                            ],
                                            attrs: {
                                              loading: _vm.isLoading,
                                              balance: _vm.displayBalance,
                                            },
                                          }),
                                          _c("mew-select", {
                                            attrs: {
                                              value: _vm.fromTokenType,
                                              label: "From",
                                              items: _vm.actualFromTokens,
                                              "is-custom": true,
                                              loading: _vm.isLoading,
                                            },
                                            on: { input: _vm.setFromToken },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("mew-input", {
                                        ref: "amountInput",
                                        staticClass: "FromAmountInput mt-2",
                                        attrs: {
                                          label: "Amount",
                                          placeholder: "0",
                                          type: "number",
                                          value: _vm.tokenInValue,
                                          "persistent-hint": true,
                                          "error-messages":
                                            _vm.amountErrorMessage,
                                          disabled: _vm.initialLoad,
                                          "buy-more-str":
                                            _vm.amountErrorMessage ===
                                              _vm.errorMsgs
                                                .amountExceedsEthBalance ||
                                            _vm.amountErrorMessage ===
                                              _vm.errorMsgs.amountEthIsTooLow
                                              ? _vm.network.type.canBuy
                                                ? "Buy more."
                                                : ""
                                              : null,
                                          "max-btn-obj": _vm.maxBtn,
                                        },
                                        on: {
                                          buyMore: function () {
                                            _vm.openBuySell("ModuleSwapInput")
                                          },
                                          input: function (val) {
                                            return _vm.triggerSetTokenInValue(
                                              val,
                                              false
                                            )
                                          },
                                        },
                                        nativeOn: {
                                          keydown: function ($event) {
                                            return _vm.preventCharE($event)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "px-6 py-0 py-sm-3 mb-3 mb-sm-0",
                                      attrs: {
                                        cols: "12",
                                        "align-self": "center",
                                        sm: "2",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-center justify-center pb-sm-10",
                                        },
                                        [
                                          _c("mew-icon-button", {
                                            staticClass:
                                              "pa-2 d-flex align-center justify-center SwitchTokens buttonGrayLight",
                                            attrs: {
                                              "mdi-icon": "swap-horizontal",
                                              "color-theme": "textDark",
                                              "btn-style": "light",
                                              disabled: !_vm.enableTokenSwitch,
                                            },
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.switchTokens.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-0 pb-sm-3 pl-sm-0",
                                      attrs: { cols: "12", sm: "5" },
                                    },
                                    [
                                      _c("mew-select", {
                                        staticClass: "ToTokenSelect",
                                        attrs: {
                                          value: _vm.toTokenType,
                                          items: _vm.actualToTokens,
                                          "is-custom": true,
                                          loading: _vm.isLoading,
                                          label: "To",
                                        },
                                        on: { input: _vm.setToToken },
                                      }),
                                      _c("mew-input", {
                                        staticClass: "mt-2",
                                        attrs: {
                                          label: "Amount",
                                          placeholder: "0",
                                          type: "number",
                                          "hide-clear-btn": true,
                                          value: _vm.tokenOutValue,
                                          "is-read-only": "",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          !_vm.hasMinEth
                            ? _c(
                                "app-user-msg-block",
                                {
                                  staticClass: "mt-5",
                                  attrs: { message: _vm.msg.lowBalance },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "mt-3 mx-n1" },
                                    [
                                      _c("mew-button", {
                                        staticClass: "ma-1",
                                        attrs: {
                                          "btn-size": "small",
                                          "btn-style": "outline",
                                          title:
                                            "Buy " +
                                            _vm.network.type.currencyName,
                                          "has-full-width":
                                            _vm.$vuetify.breakpoint.xsOnly,
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            return function () {
                                              _vm.openBuySell(
                                                "ModuleSwapErrorBlock"
                                              )
                                            }.apply(null, arguments)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "mt-8" },
                            [
                              _vm.isFromNonChain
                                ? _c("module-address-book", {
                                    ref: "refundAddressInput",
                                    staticClass: "FromAddressInput",
                                    attrs: {
                                      label: _vm.nativeLabel,
                                      "is-valid-address-func":
                                        _vm.isValidRefundAddress,
                                    },
                                    on: { setAddress: _vm.setRefundAddr },
                                  })
                                : _vm._e(),
                              _c("module-address-book", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.showToAddress,
                                    expression: "showToAddress",
                                  },
                                ],
                                ref: "toAddressInput",
                                staticClass: "ToAddressInput",
                                attrs: {
                                  "is-valid-address-func": _vm.isValidToAddress,
                                  label: _vm.toAddressLabel,
                                },
                                on: { setAddress: _vm.setToAddress },
                              }),
                            ],
                            1
                          ),
                          _vm.toTokenType &&
                          _vm.toTokenType.value &&
                          _vm.toTokenType.value.toLowerCase() == "bitcoin" &&
                          _vm.isEthNetwork
                            ? _c(
                                "app-user-msg-block",
                                {
                                  staticClass: "mt-sm-5",
                                  attrs: { message: _vm.msg.storeBitcoin },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "border-top mt-3" },
                                    [
                                      _c(
                                        "v-expansion-panels",
                                        {
                                          staticClass:
                                            "expansion-panels--remove-paddings",
                                          attrs: { flat: "" },
                                        },
                                        [
                                          _c(
                                            "v-expansion-panel",
                                            [
                                              _c(
                                                "v-expansion-panel-header",
                                                {
                                                  staticClass:
                                                    "textLight--text",
                                                  attrs: { color: "greyLight" },
                                                },
                                                [
                                                  _vm._v(
                                                    " How can I get wrapped Bitcoin? "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-expansion-panel-content",
                                                {
                                                  staticClass: "pa-0",
                                                  attrs: { color: "greyLight" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "textLight--text mb-2",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " When you swap to Bitcoin, it is moved to the Bitcoin blockchain, & requires a Bitcoin wallet. In order to keep Bitcoin in MyEtherWallet, you can swap to wrapped Bitcoin instead. Wrapped Bitcoin is an Ethereum token, with a value approximately equal to 1 BTC. Wrapped Bitcoins can be stored in MEW, and can be used as any other Ethereum asset: you can swap it to other tokens, use it as collateral in DeFi apps, etc. There are multiple kinds of wrapped Bitcoins, but they roughly do the same thing. "
                                                      ),
                                                      _c(
                                                        "a",
                                                        {
                                                          attrs: {
                                                            href: _vm.getArticle(
                                                              "mv-btc-to-eth-mew-swap"
                                                            ),
                                                            target: "_blank",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Learn more about Wrapped Bitcoin. "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    { staticClass: "mt-6" },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "12",
                                                            md: "4",
                                                          },
                                                        },
                                                        [
                                                          _c("mew-button", {
                                                            attrs: {
                                                              "btn-size":
                                                                "small",
                                                              "btn-style":
                                                                "outline",
                                                              title:
                                                                "Swap to renBTC",
                                                              "has-full-width": true,
                                                            },
                                                            nativeOn: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.swapTo(
                                                                  "renBTC"
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "12",
                                                            md: "4",
                                                          },
                                                        },
                                                        [
                                                          _c("mew-button", {
                                                            attrs: {
                                                              "btn-size":
                                                                "small",
                                                              "btn-style":
                                                                "outline",
                                                              title:
                                                                "Swap to wBTC",
                                                              "has-full-width": true,
                                                            },
                                                            nativeOn: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.swapTo(
                                                                  "wBTC"
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "12",
                                                            md: "4",
                                                          },
                                                        },
                                                        [
                                                          _c("mew-button", {
                                                            attrs: {
                                                              "btn-size":
                                                                "small",
                                                              "btn-style":
                                                                "outline",
                                                              title:
                                                                "Swap to PBTC",
                                                              "has-full-width": true,
                                                            },
                                                            nativeOn: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.swapTo(
                                                                  "PBTC"
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.hasMinEth
                            ? _c(
                                "div",
                                [
                                  _vm.showAnimation
                                    ? _c(
                                        "v-slide-y-transition",
                                        {
                                          attrs: {
                                            "hide-on-leave": "",
                                            group: "",
                                          },
                                        },
                                        [
                                          _c("swap-provider-mentions", {
                                            key: "showAnimation",
                                            attrs: {
                                              "is-loading":
                                                _vm.isLoadingProviders,
                                              "check-loading": _vm.checkLoading,
                                            },
                                            on: {
                                              showProviders: _vm.showProviders,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        { key: "showAnimation1" },
                                        [
                                          _c("swap-providers-list", {
                                            class: _vm.isFromNonChain
                                              ? ""
                                              : "mt-7",
                                            attrs: {
                                              step: _vm.step,
                                              "available-quotes":
                                                _vm.availableQuotes,
                                              "set-provider": _vm.setProvider,
                                              "to-token-symbol": _vm.toTokenType
                                                ? _vm.toTokenType.symbol
                                                : "",
                                              "to-token-icon": _vm.toTokenType
                                                ? _vm.toTokenType.img
                                                : "",
                                              "is-loading":
                                                _vm.isLoadingProviders,
                                              "providers-error":
                                                _vm.providersErrorMsg,
                                              "selected-provider-id":
                                                _vm.selectedProviderId,
                                            },
                                          }),
                                          _vm.hasGasPriceOption && _vm.step >= 1
                                            ? _c(
                                                "p",
                                                { staticClass: "error--text" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.feeError
                                                          ? _vm.feeError
                                                          : _vm.providersErrorMsg
                                                          ? _vm
                                                              .providersErrorMsg
                                                              .subtitle
                                                          : ""
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.showNetworkFee
                                            ? _c("transaction-fee", {
                                                staticClass: "mt-10 mt-sm-16",
                                                attrs: {
                                                  "is-from-chain":
                                                    !_vm.isFromNonChain,
                                                  "show-fee": _vm.showSwapFee,
                                                  "getting-fee": _vm.loadingFee,
                                                  error: _vm.feeError,
                                                  "total-cost": _vm.totalCost,
                                                  "tx-fee": _vm.txFee,
                                                  "total-gas-limit":
                                                    _vm.totalGasLimit,
                                                  "not-enough-eth":
                                                    _vm.notEnoughEth,
                                                  "from-eth":
                                                    _vm.isFromTokenMain,
                                                  "is-swap": "",
                                                },
                                                on: {
                                                  onLocalGasPrice:
                                                    _vm.handleLocalGasPrice,
                                                },
                                              })
                                            : _vm._e(),
                                          _vm.showNextButton
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-center mt-10 mt-sm-15",
                                                },
                                                [
                                                  _c("mew-button", {
                                                    staticClass: "NextButton",
                                                    staticStyle: {
                                                      "max-width": "240px",
                                                    },
                                                    attrs: {
                                                      title: "Next",
                                                      "has-full-width": true,
                                                      disabled: _vm.disableNext,
                                                      "btn-size": "xlarge",
                                                    },
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        return _vm.showConfirm()
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    }
                  : {
                      key: "moduleBody",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "swap-not-available" },
                            [
                              _c("app-user-msg-block", {
                                attrs: { message: _vm.swapNotAvailableMes },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }