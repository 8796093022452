var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("app-modal", {
        attrs: {
          show: _vm.showSuccessModal,
          title: _vm.successTitle,
          close: _vm.resetSuccess,
          "close-only": true,
          "has-close-button": _vm.hasCloseButton,
          width: "480",
        },
        on: { close: _vm.resetSuccess },
        scopedSlots: _vm._u([
          {
            key: "dialogBody",
            fn: function () {
              return [
                _c(
                  "div",
                  [
                    _vm.showSuccessModal
                      ? _c("div", {
                          directives: [
                            {
                              name: "lottie",
                              rawName: "v-lottie",
                              value: _vm.successLottie,
                              expression: "successLottie",
                            },
                          ],
                          class: [_vm.showSuccessSwap ? "py-7" : "", "lottie"],
                        })
                      : _vm._e(),
                    _c("div", { staticClass: "mew-body" }, [
                      _vm._v(" " + _vm._s(_vm.successBodyText) + " "),
                    ]),
                    _c(
                      "v-row",
                      {
                        staticClass:
                          "justify-sm-space-around align-center pt-3",
                        attrs: { dense: "" },
                      },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "pb-2",
                            attrs: { cols: "12", sm: "auto", "order-sm": "3" },
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "d-flex justify-center justify-sm-end",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.viewProgress.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [_vm._v("View Progress")]
                            ),
                          ]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "pb-2",
                            attrs: { cols: "12", sm: "auto" },
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "d-flex justify-center justify-sm-start",
                                attrs: {
                                  rel: "noopener noreferrer",
                                  target: "_blank",
                                  href: _vm.links.explorer,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.trackExplorrer(_vm.explorerText)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "View on " + _vm._s(_vm.explorerText) + " "
                                ),
                                _c(
                                  "v-icon",
                                  { attrs: { color: "primary", small: "" } },
                                  [_vm._v("mdi-launch")]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }