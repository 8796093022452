var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "mew-overlay",
    {
      attrs: {
        footer: {
          text: "Need help?",
          linkTitle: "Contact support",
          link: "mailto:support@xinfin.org",
        },
        "show-overlay": _vm.open,
        title: _vm.title,
        close: _vm.closeEdit,
        back: _vm.back,
        "content-size": "xlarge",
      },
    },
    [
      _c(
        "div",
        { staticClass: "full-width" },
        [
          _c(
            "div",
            { staticClass: "text-right my-4" },
            [
              _c("mew-button", {
                attrs: {
                  title: "+ Add Token",
                  "color-theme": "basic",
                  "btn-style": "light",
                  "btn-size": "medium",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.addCustomToken.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
          !_vm.isMobile
            ? _c(
                "mew-light-table",
                { attrs: { flat: "", divider: "", "full-width": "" } },
                [
                  _c("table", [
                    _c("thead", [
                      _c("tr", { staticClass: "text-uppercase" }, [
                        _c("td", [_vm._v("Show")]),
                        _c("td", [_vm._v("Token")]),
                        _c("td", [_vm._v("Balance")]),
                        _c("td", [_vm._v("Address")]),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v("Remove"),
                        ]),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      _vm._l(
                        _vm.formattedAllTokens,
                        function (token, tableKey) {
                          return _c("tr", { key: tableKey }, [
                            _c(
                              "td",
                              [
                                _c("v-checkbox", {
                                  attrs: {
                                    "hide-details": "",
                                    color: "primary",
                                    "on-icon": "mdi-check-circle",
                                    "off-icon":
                                      "mdi-checkbox-blank-circle-outline",
                                    "input-value": token.checkbox.value,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return token.checkbox.method(token)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("td", [
                              _c(
                                "div",
                                { staticClass: "d-flex align-center" },
                                [
                                  _c("mew-token-container", {
                                    staticClass: "mr-2",
                                    attrs: { img: token.tokenImg },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "font-weight-medium" },
                                    [_vm._v(_vm._s(token.token))]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  token.balance[0].replace(token.token, "")
                                )
                              ),
                            ]),
                            _c("td", [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex align-center",
                                  staticStyle: { "max-width": "150px" },
                                },
                                [
                                  _c("mew-transform-hash", {
                                    staticClass: "mr-2",
                                    attrs: { hash: token.address },
                                  }),
                                  _c("mew-copy", {
                                    attrs: {
                                      "copy-value": token.address,
                                      "is-small": "",
                                    },
                                  }),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: _vm.explorerAddr(token.address),
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "basic--text",
                                          attrs: { small: "" },
                                        },
                                        [_vm._v(" mdi-launch ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "td",
                              { staticClass: "text-center" },
                              [
                                token.callToAction.length > 0
                                  ? _c("mew-button", {
                                      attrs: {
                                        title: "Remove",
                                        "btn-style": "transparent",
                                        "color-theme": "error",
                                        "btn-size": "small",
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          return token.callToAction[0].method(
                                            token
                                          )
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ])
                        }
                      ),
                      0
                    ),
                  ]),
                ]
              )
            : _c(
                "div",
                { staticClass: "mt-6" },
                _vm._l(
                  _vm.formattedAllTokens,
                  function (token, mobileTableKey) {
                    return _c(
                      "div",
                      { key: mobileTableKey, staticClass: "mobile-table py-5" },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex align-center mb-3" },
                          [
                            _c("v-checkbox", {
                              attrs: {
                                "hide-details": "",
                                color: "primary",
                                "on-icon": "mdi-check-circle",
                                "off-icon": "mdi-checkbox-blank-circle-outline",
                                "input-value": token.checkbox.value,
                              },
                              on: {
                                change: function ($event) {
                                  return token.checkbox.method(token)
                                },
                              },
                            }),
                            _c("mew-token-container", {
                              staticClass: "mr-2",
                              attrs: { img: token.tokenImg },
                            }),
                            _c("div", { staticClass: "font-weight-medium" }, [
                              _vm._v(_vm._s(token.token)),
                            ]),
                            _c("v-spacer"),
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  token.balance[0].replace(token.token, "")
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex align-center" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "d-flex align-center",
                                staticStyle: { "max-width": "150px" },
                              },
                              [
                                _c("mew-transform-hash", {
                                  staticClass: "mr-2",
                                  attrs: { hash: token.address },
                                }),
                                _c("mew-copy", {
                                  attrs: {
                                    "copy-value": token.address,
                                    "is-small": "",
                                  },
                                }),
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: _vm.explorerAddr(token.address),
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "basic--text",
                                        attrs: { small: "" },
                                      },
                                      [_vm._v(" mdi-launch ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("v-spacer"),
                            token.callToAction.length > 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "error--text font-weight-medium cursor--pointer",
                                    on: {
                                      click: function () {
                                        return _vm.removeToken(token)
                                      },
                                    },
                                  },
                                  [_vm._v(" Remove ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    )
                  }
                ),
                0
              ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }