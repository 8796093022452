var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "mx-auto pb-3", staticStyle: { "max-width": "550px" } },
      [
        _c("div", { staticClass: "mew-heading-2 py-8 text-center" }, [
          _vm._v("Review and stake"),
        ]),
        _c(
          "v-row",
          { attrs: { dense: "" } },
          _vm._l(_vm.details, function (detail, idx) {
            return _c(
              "v-col",
              { key: detail + idx, attrs: { cols: "12", sm: "6" } },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "greyLight pa-5 d-flex align-center rounded-lg",
                  },
                  [
                    _c("div", { staticClass: "mr-3 mt-1" }, [
                      _c("img", {
                        attrs: {
                          src: detail.img,
                          height: "25",
                          alt: "eth icon",
                        },
                      }),
                    ]),
                    _c(
                      "div",
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "textLight--text text-uppercase detail-subtitle font-weight-medium",
                          },
                          [_vm._v(" " + _vm._s(detail.subtitle) + " ")]
                        ),
                        _c(
                          "div",
                          { staticClass: "mew-heading-4 detail-title" },
                          [_vm._v(_vm._s(detail.title))]
                        ),
                        detail.isAddress
                          ? _c("mew-transform-hash", {
                              staticClass:
                                "mew-hash-container textLight--text font-weight-medium",
                              attrs: { hash: detail.desc },
                            })
                          : _vm._e(),
                        !detail.isAddress
                          ? _c("div", { staticClass: "textLight--text" }, [
                              _vm._v(" " + _vm._s(detail.desc) + " "),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            )
          }),
          1
        ),
        _c(
          "div",
          { staticClass: "mt-5" },
          _vm._l(_vm.fees, function (fee, idx) {
            return _c(
              "div",
              {
                key: fee + idx,
                staticClass:
                  "d-block d-sm-flex align-center justify-space-between mb-3 mb-sm-2",
              },
              [
                _c("div", { staticClass: "mew-caption textLight--text" }, [
                  _vm._v(_vm._s(fee.title)),
                ]),
                _c("div", [
                  _vm._v(" " + _vm._s(fee.ethValue) + " "),
                  _c("span", { staticClass: "textLight--text" }, [
                    _vm._v("ETH /"),
                  ]),
                  _vm._v(" " + _vm._s(fee.fiatValue) + " "),
                ]),
              ]
            )
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "mt-11 mt-sm-10 pa-5 greyLight" },
          [
            _c("mew-checkbox", {
              attrs: {
                dense: "",
                "class-name": "textMedium--text",
                label:
                  "I understand that staking involves slashing risks and my funds can be lost.",
              },
              model: {
                value: _vm.secondCondition,
                callback: function ($$v) {
                  _vm.secondCondition = $$v
                },
                expression: "secondCondition",
              },
            }),
            _c("mew-checkbox", {
              attrs: { dense: "", "class-name": "textMedium--text" },
              scopedSlots: _vm._u([
                {
                  key: "contentSlot",
                  fn: function () {
                    return [
                      _c("span", [
                        _vm._v("I have read and agreed to "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://staked.us/terms/",
                              target: "_blank",
                            },
                          },
                          [_vm._v("Staked.us")]
                        ),
                        _vm._v(
                          " terms of service. This Staking feature is provided by Staked.us, and MEW is not liable for it's services."
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value: _vm.thirdCondition,
                callback: function ($$v) {
                  _vm.thirdCondition = $$v
                },
                expression: "thirdCondition",
              },
            }),
          ],
          1
        ),
        _c(
          "border-block",
          { staticClass: "mt-6 mx-md-2 text-center rounded-lg pa-6" },
          [
            _vm.stakedStep === 1
              ? _c(
                  "div",
                  [
                    _c(
                      "div",
                      {
                        class: [
                          "mx-auto",
                          _vm.stakedStep1Title.error
                            ? "redPrimary--text"
                            : "textMedium--text",
                        ],
                        staticStyle: { "max-width": "300px" },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.stakedStep1Title.message) + " "
                        ),
                        _vm.stakedStep1Title.showContactSupport
                          ? _c(
                              "a",
                              {
                                staticClass:
                                  "cursor-pointer greenPrimary--text text-lowercase",
                                attrs: {
                                  rel: "noopener noreferrer",
                                  href: "mailto:support@xinfin.org",
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("common.contact-support")) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                    _c("mew-button", {
                      staticClass: "mt-3",
                      attrs: {
                        "btn-size": "xlarge",
                        title: "Prepare for staking",
                        disabled: !_vm.secondCondition || !_vm.thirdCondition,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.prepareToStake.apply(null, arguments)
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.stakedStep === 2
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "mew-heading-4 font-weight-medium mb-2" },
                    [_vm._v(" Preparing validators ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "textMedium--text mx-auto",
                      staticStyle: { "max-width": "300px" },
                    },
                    [
                      _vm._v(
                        " This usually takes ~20 seconds, in rare cases it can take up to 10 min. "
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "px-5" },
                    [
                      _c("v-progress-linear", {
                        staticClass: "mt-4 mx-auto",
                        staticStyle: { "max-width": "350px" },
                        attrs: { indeterminate: "", color: "greenPrimary" },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm.stakedStep === 3
              ? _c(
                  "div",
                  [
                    _c(
                      "v-icon",
                      { staticClass: "mr-2", attrs: { color: "greenPrimary" } },
                      [_vm._v("mdi-check-circle")]
                    ),
                    _vm._v(" Ready to stake "),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "mt-10 d-flex flex-column-reverse flex-md-row align-center justify-center",
          },
          [
            _c("mew-button", {
              staticClass: "d-block ma-2",
              attrs: {
                "has-full-width": _vm.$vuetify.breakpoint.smAndDown,
                "btn-size": "xlarge",
                title: "Back",
                "btn-style": "outline",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onBack.apply(null, arguments)
                },
              },
            }),
            _c("mew-button", {
              staticClass: "d-block ma-2",
              attrs: {
                "has-full-width": _vm.$vuetify.breakpoint.smAndDown,
                "btn-size": "xlarge",
                title: _vm.buttonTitle,
                disabled: !_vm.readyToStake,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onReadyToStake.apply(null, arguments)
                },
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }