var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "core--components--app-transaction-fee" },
    [
      _c("app-modal", {
        attrs: {
          show: _vm.openHighFeeNote,
          close: _vm.closeHighFeeNote,
          "has-buttons": false,
        },
        scopedSlots: _vm._u([
          {
            key: "dialogBody",
            fn: function () {
              return [
                _c("div", { staticClass: "mew-heading-1 mb-2" }, [
                  _vm._v("How are fees determined?"),
                ]),
                _c("div", { staticClass: "mt-6 textLight--text" }, [
                  _vm._v(
                    " Transaction fees are charged by Ethereum miners, not MEW. We can't influence them and we don't receive any part of the transaction fees that you pay. "
                  ),
                ]),
                _c("div", { staticClass: "mew-heading-1 my-2 mt-6" }, [
                  _vm._v("What should I do?"),
                ]),
                _c("div", { staticClass: "mt-6 textLight--text" }, [
                  _vm._v(
                    " Good news! You have options! If you’re not in a hurry, you can use the “Normal” setting and your transaction will be mined at a later time. MEW supports Ethereum scaling solutions Polygon and Binance Smart Chain (accessible on MEW web and Android). Consider using these chains to avoid congestion and save on fees. Learn how "
                  ),
                  _c(
                    "a",
                    {
                      attrs: {
                        rel: "noopener noreferrer",
                        target: "_blank",
                        href: "https://help.myetherwallet.com/en/?q=network",
                      },
                    },
                    [_vm._v("here")]
                  ),
                  _vm._v(". "),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("app-network-settings-modal", {
        attrs: {
          close: _vm.closeGasPrice,
          "gas-price-modal": _vm.gasPriceModal,
          "not-enough-eth": _vm.notEnoughEth,
          "cost-in-eth": _vm.costInEth,
          "tx-fee-formatted": _vm.txFeeFormatted,
          "tx-fee-usd": _vm.feeInUsd,
          "total-gas-limit": _vm.totalGasLimit,
        },
        on: {
          onLocalGasPrice: _vm.handleLocalGasPrice,
          close: _vm.closeGasPrice,
        },
      }),
      _c("div", { staticClass: "d-flex align-center mb-2" }, [
        _c("div", { staticClass: "mew-heading-3 ml-2" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.hasGasPriceOption ? "Estimated Fee" : "Transaction Fee"
              ) +
              " "
          ),
        ]),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.gettingFee || !_vm.showFee,
              expression: "gettingFee || !showFee",
            },
          ],
          staticStyle: { "max-width": "556px" },
        },
        [
          _c("v-skeleton-loader", {
            staticClass: "mt-2 px-sm-2 align-center",
            attrs: { type: "heading" },
          }),
          _c("v-skeleton-loader", {
            staticClass: "mt-2 px-sm-2 align-center d-block d-sm-none",
            attrs: { type: "heading" },
          }),
        ],
        1
      ),
      !_vm.gettingFee && _vm.showFee
        ? _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex align-center justify-space-between flex-wrap mb-2",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-center flex-wrap flex-grow-1",
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "text-transform--initial",
                            attrs: {
                              color: "buttonGrayLight",
                              depressed: "",
                              disabled:
                                _vm.hasGasPriceOption || _vm.error !== "",
                            },
                            on: { click: _vm.openGasPriceModal },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "d-flex align-center" },
                              [
                                _c(
                                  "div",
                                  {
                                    class: _vm.hasError
                                      ? "redPrimary--text"
                                      : "textMedium--text",
                                  },
                                  [_vm._v(" " + _vm._s(_vm.feeInUsd) + " ")]
                                ),
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mx-2",
                                    attrs: {
                                      color: _vm.hasError ? "redPrimary" : "",
                                      small: "",
                                    },
                                  },
                                  [_vm._v(" mdi-arrow-right ")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-center" },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          color: _vm.hasError
                                            ? "redPrimary"
                                            : "",
                                          small: "",
                                        },
                                      },
                                      [_vm._v(" mdi-clock-outline ")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        class: _vm.hasError
                                          ? "redPrimary--text"
                                          : "",
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.timeWillTake) + " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                !_vm.hasGasPriceOption
                                  ? _c(
                                      "v-icon",
                                      {
                                        staticClass: "ml-3",
                                        attrs: {
                                          color: _vm.hasError
                                            ? "redPrimary"
                                            : "",
                                        },
                                      },
                                      [_vm._v(" mdi-chevron-down ")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "ml-3 py-1",
                            class: _vm.hasError
                              ? "redPrimary--text"
                              : "textLight--text",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.txFeeFormatted) +
                                " " +
                                _vm._s(_vm.network.type.currencyName) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm.fromEth
                      ? _c("div", [
                          _c("div", { staticClass: "py-2 ml-2 text-right" }, [
                            _c("div", { staticClass: "textMedium--text" }, [
                              _c(
                                "span",
                                { staticClass: "mr-2 textMedium--text" },
                                [_vm._v("Total:")]
                              ),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.actualCostFormatted) +
                                  " " +
                                  _vm._s(_vm.network.type.currencyName) +
                                  " "
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "d-flex flex-wrap justify-space-between" },
                  [
                    !_vm.gettingFee & _vm.hasError
                      ? _c(
                          "div",
                          {
                            staticClass: "ml-2",
                            class: [_vm.hasError ? "redPrimary--text" : ""],
                          },
                          [
                            _vm._v(" " + _vm._s(_vm.error) + " "),
                            _vm.notEnoughEth && _vm.network.type.canBuy
                              ? _c(
                                  "a",
                                  {
                                    on: {
                                      click: function () {
                                        _vm.openBuySell("TransactionFee")
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " Buy more " +
                                        _vm._s(_vm.network.type.currencyName) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                    _c("div", [
                      _vm.isEthNetwork
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "ml-2 greenPrimary--text cursor--pointer user-select--none",
                              on: { click: _vm.showHighNote },
                            },
                            [_vm._v(" How are fees determined? ")]
                          )
                        : _vm._e(),
                    ]),
                  ]
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }